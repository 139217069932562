.text-error {
    /* --bs-text-opacity: 1;
    color: red !important; */
    display: none;
}

.bg-ontime-true {
    background-color: rgb(178, 126, 107);
}

.bg-ontime-false {
    background-color: rgb(149, 183, 189);
}

.modal-width {
    width: 30%;
    max-width: 50%;
}

.modal-position {
    position: fixed;
    top: 0px;
    right: 20px;
}