.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.pin-effect {
    width: 15px;
    height: 15px;
    border-radius: 50% 50% 50% 10%;
    background: #182c70;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -10px 0 0 -13px;
}
.pin-effect:after {
    content: "";
    width: 6px;
    height: 6px;
    margin: 4px 0 0 5px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
}

.bounce-effect {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.pulse-effect {
    background: #d6d4d4;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 6px 0px 0px -10px;
    transform: rotateX(55deg);
    z-index: -2;
}

.pulse-effect:after {
    content: "";
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    margin: -9px 0 0 -10px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #182c70;
    animation-delay: 1.1s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }
    
    100% {
        transform: translateY(0) rotate(-45deg);
    }
}