.sticky-headers {
    position: sticky;
    top: 0;
    background: white !important;

}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 36px;
}

.centered {
    text-align: center;
}